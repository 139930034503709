<template>
  <modal
    name="complete"
    class="payment-popup"
    transition="pop-out"
    :adaptive="true"
    @before-open="setTransactionInfo"
  >
    <div class="payment-popup-container">
      <form
          class="form form--payment js-ajaxForm whitePicker"
          id="paymentForm"
          :class="{'loading' : loading}"
          @submit.prevent="submitHandler"
        >
        <div class="payment-popup-inner-form" style="padding: 45px 90px 0px;">

              <h1 class="payment-popup-container__title"> {{ title || 'Payment Details' }} </h1>
              <h3 class="payment-popup-container__subtitle">{{ subTitle || '$'+price }}</h3>
              <div
                class="payment-popup-container__description"
              >
                {{ description || paymentTypeText }}
              </div>
              <Spinner />
              <label class="form__label form__label--text" style="margin-bottom: 0px;">
                <div class="caption">Name:</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    style="text-align: center"
                    type="text"
                    v-model.trim="name"
                    placeholder=""
                  />
                </div>
                <span
                  v-if="$v.name.$dirty && !$v.name.required"
                  class="form__field-invalid form__field-invalid--right-side"
                >
                  This field is required
                </span>
              </label>
              <div class="request-popup__radioGroup" style="padding: 23px 0 23px 0;">
                <label class="form__label--radio request-popup__label-radio checked">
                  <input class="request-popup__field-radio" type="radio" :value="true" name="payment" v-model="inputNewCard" data-idpayment="2">
                  <i class="request-popup__radio-icon"></i>
                  <div class="request-popup__caption request-popup__caption--radio">new Card</div>
                </label>
                <label v-if="savedCards.length > 0" class="form__label--radio request-popup__label-radio">
                  <input class="request-popup__field-radio" type="radio" name="payment" :value="false" data-idpayment="1" v-model="inputNewCard">
                  <i class="request-popup__radio-icon"></i>
                  <div class="request-popup__caption request-popup__caption--radio">Saved card</div>
                </label>
              </div>
        </div>
        <div class="payment-popup-inner-form" style="border-bottom-right-radius: 30px; border-bottom-left-radius: 30px; padding: 0px 43px 50px; background-color: #2c2d31">
              <div class="card-details" v-if="inputNewCard">
                <div
                  class="card-info-top"
                  style="align-items: center;"
                >
                  <label class="form__label card form__label--text" style="margin-bottom: 33px">
                    <div class="form__caption">Card number:</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field js-numberOnly"
                        type="text"
                        v-model="card_number"
                        v-mask="'####-####-####-####'"
                        minLength="16"
                        autoComplete="off"
                      />
                    </div>
                    <span
                      v-if="$v.card_number.$dirty && !$v.card_number.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                  This field is required
                </span>
                  </label>

                  <label class="form__label form__label--date" style="margin-bottom: 33px">
                    <div class="form__caption">Expiration: <span style="font-size: .5em;">(mm/yyyy)</span></div>
                    <div class="form__field-wrapper">
    <!--                  <a-month-picker-->
    <!--                    class="form__field js-gmi-datepicker"-->
    <!--                    placeholder=""-->
    <!--                    :showToday="false"-->
    <!--                    :allowClear="false"-->
    <!--                    v-model="date"-->
    <!--                    :format="'MM/YYYY'"-->
    <!--                    :getCalendarContainer="getPickerContainer"-->
    <!--                  />-->
                      <input
                        class="form__field js-numberOnly"
                        type="text"
                        v-model="date"
                        v-mask="'##/####'"
                        minLength="7"
                        autoComplete="off"
                      />
                    </div>
                    <span
                      v-if="$v.date.$dirty && !$v.date.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                  This field is required
                </span>
                  </label>
                </div>

                <div
                  class="card-info-bottom"
                  style="align-items: center;"
                >
                  <label class="form__label cvv form__label--code" style="margin-bottom: 0px;">
                    <div class="form__caption" style="margin-top: 0px">CVV:</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field js-numberOnly"
                        type="text"
                        v-model="code"
                        v-mask="'####'"
                        minLength="3"
                        autoComplete="off"
                      />
                    </div>
                    <span
                      v-if="$v.code.$dirty && !$v.code.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                  This field is required
                </span>
                  </label>
                  <label class="form__label form__label--checkbox " :class="{'checked' : saveCardFuture}">
                    <input class="form__checkbox" type="checkbox" v-model="saveCardFuture" :checked="saveCardFuture" :disabled="disableSaveCardFuture"/>
                    <i class="form__checkbox-icon"></i>
                    <span class="form__checkbox-box">Save payment for future use?</span>
                  </label>

                  <!-- <label class="form__label form__label--code">
                    <div class="form__caption">Billing Address:</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="billing_address"
                        required
                        autoComplete="off"
                      />
                    </div>
                  </label> -->
                </div>
              </div>
              <div style="padding-bottom: 22px" v-else>
                <vue-scroll :ops="ops" class="card-list">
                <div v-if="!currentCard">
                  <div style="color: #fff; margin: 20px;">Click to select card</div>
                  <div class="card" v-for="card of savedCards" :key="card.id" @click="() => selectCard(card.id)" >
                    <p>{{card.creditCardBrand}} ************{{card.creditCardLast4}} {{card.creditCardExpMonth}}/{{card.creditCardExYear}}</p>
                  </div>
                </div>

                <div v-else class="card-details">
                  <div
                    class="card-info-top"
                    style="align-items: center;"
                  >
                    <label class="form__label card form__label--text">
                      <div class="form__caption">Card number:</div>
                      <div class="form__field-wrapper">
                        <input
                          class="form__field"
                          type="text"
                          :value="`************${currentCard.creditCardLast4}`"
                          autoComplete="off"
                          disabled
                          required
                        />
                      </div>
                    </label>

                    <label class="form__label form__label--date">
                      <div class="form__caption">Expiration:</div>
                      <div class="form__field-wrapper">
                        <input
                          class="form__field js-numberOnly"
                          type="text"
                          :value="`${currentCard.creditCardExpMonth}/${currentCard.creditCardExYear}`"
                          disabled
                          autoComplete="off"
                          required
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </vue-scroll>
              </div>
              <div class="buttonWrapp" style="marginTop: 50px;">
                <button
                  class="buttonGreen"
                  v-if="inputNewCard || currentCard"
                  style="margin-top: 50px; padding: 21px 57px;"
                  :disabled="loading"
                >
                  <span class="text">
                    {{ transactionType === 'subscription' ? 'Send the Payment Method' : 'Send Payment' }}
                  </span>
                </button>
              </div>
        </div>
      </form>
      <div class="modal__close transaction-popup__close" @click="()=>{closeModal(); $modal.hide('complete')}">
        <SvgIcon name="close-white"/>
      </div>
    </div>
  </modal>

</template>

<script>
import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
import { usersAPI } from '@/api/accounts'
import Spinner from '@/components/common/Spinner/Spinner'
import './PaymentPopup.styl'
import {required} from 'vuelidate/lib/validators';

export default {
  name: 'CompleteTransactionPopUp',

  data: () => ({
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: '#bdbaba',
      },
    },
    name: '',
    typeCard: 'credit',
    address: '',
    loading: false,
    card_number: false,
    expiration: '',
    code: '',
    date: '',
    billing_address: '',
    transactionType: null,
    bookingNum: null,
    requestId: null,
    paymentTypeText: '',
    price: 0,
    inputNewCard: true,
    saveCardFuture: false,
    disableSaveCardFuture: false,
    savedCards: [],
    currentCard: null,
    title: null,
    subTitle: null,
    description: null,
  }),
  validations: {
    date: { required },
    code: { required },
    name: {required},
    card_number: {required},
  },
  methods: {
    async setTransactionInfo (event) {
      this.title = event.params?.title;
      this.subTitle = event.params?.subTitle;
      this.description = event.params?.description;

      this.savedCards = await this.$store.dispatch('getPaymethods', true)
      this.transactionType = event.params?.transactionType
      switch (this.transactionType) {
        case 'manual-request':
          this.requestId = event.params?.requestId
          this.paymentTypeText = 'Manual Request Reservation Fee';
          break;
        case 'booking':
          this.bookingNum = this.$store.state.bookingFromSearch.bookingNo
          this.paymentTypeText = 'Administrative fee for aircraft booking';
          break;
        case 'flycal':
          this.paymentTypeText = 'Access as a FLYCalendar member';
          break;
        case 'subscription':
          this.saveCardFuture = true
          this.disableSaveCardFuture = true;
          break;
      }
      this.price = event.params?.price;
    },
    closeModal () {
      this.currentCard = null
      this.saveCardFuture = false
      this.card_number = false
      this.code = ''
      this.date = ''
      this.inputNewCard = true
    },
    async submitHandler () {
      if (this.$v.$invalid && this.inputNewCard) {
        this.$v.$touch()
        return false
      }
      if (this.transactionType === 'subscription') {
        await this.getPaymentMethodForSubscription()
        return
      }
      if (this.currentCard && !this.inputNewCard) {
        if (this.name.trim().length === 0) {
          if (this.$v.$invalid) {
            this.$v.$touch()
            return false
          }
        }

        this.loading = true
        const data = {
          paymentType: this.transactionType === 'manual-request' ? 'manual' : this.transactionType,
          paymentMethodId: this.currentCard.id,
        }
        if (this.transactionType === 'manual-request') {
          data.reqId = this.requestId
        }
        if (this.transactionType === 'booking') {
          data.reqNo = this.bookingNum
        }
        await usersAPI.paymentBySavedCard(data).then((res) => {
          if (res.data.isSuccessfull) {
            this.$emit('success')
          } else {
            if (res.data.message) {
              this.$emit('error', { message: `${res.data.message}` });
            } else {
              this.$emit('error', { message: 'Something went wrong' });
            }
          }
        }).catch(e => {
          console.log(e)
        }).finally(() => {
          this.loading = false
          this.currentCard = null
          this.inputNewCard = true
        })
      } else {
        this.loading = true

        const expMonth = this.date.split('/')[0]
        const expYear = this.date.split('/')[1]

        const payload = {
          name: this.name,
          cardNumber: this.card_number.replace(/-/g, ''),
          expMonth,
          expYear,
          cvc: this.code,
          address: this.address,
        }
        try {
          let response = null;

          switch (this.transactionType) {
            case 'manual-request':
              payload.reqGuid = this.requestId
              if (this.saveCardFuture) {
                response = await usersAPI.manualRequestDepositAndStore(payload);
              } else {
                response = await usersAPI.manualRequestDeposit(payload);
              }
              break;
            case 'booking':
              payload.reqNo = this.bookingNum
              if (this.saveCardFuture) {
                response = await usersAPI.bookingAircraftDepositAndStore(payload);
              } else {
                response = await usersAPI.bookingAircraftDeposit(payload);
              }
              break;
            case 'flycal':
              if (this.saveCardFuture) {
                response = await usersAPI.flyCalPaymentAndStore(payload);
              } else {
                response = await usersAPI.flyCalPayment(payload);
              }
              break;
          }
          if (!response.data.isSuccessfull) {
            this.$emit('error', { message: response.data.Error });
            return
          }
          this.$emit('success')
        } catch (e) {
          console.log(e)
          this.$emit('error', { message: 'Something went wrong' });
        } finally {
          this.loading = false
          this.saveCardFuture = false
          this.inputNewCard = true
        }
      }
    },
    async getPaymentMethodForSubscription () {
      this.loading = true

      try {
        if (this.currentCard && !this.inputNewCard) {
          if (this.name.trim().length === 0) {
            if (this.$v.$invalid) {
              this.$v.$touch()
              return false
            }
          }

          this.$emit('paymentMethod', this.currentCard.id)
        } else {
          const data = {
            paymentMethod: {
              type: 1,
              card: {
                number: this.card_number.replace(/-/g, ''),
                expYear: +this.date.split('/')[1],
                expMonth: +this.date.split('/')[0],
                cvc: this.code,
              },
            },
          }
          const paymentMethod = await usersAPI.createPaymentMethod(data)
          this.$emit('paymentMethod', paymentMethod.id)
        }
      } catch (e) {
        console.error(e)
        this.$emit('error', { message: 'Something went wrong' });
      } finally {
        this.loading = false
      }
    },
    selectCard (id) {
      this.currentCard = this.savedCards.filter(card => card.id === id)[0]
    },
    getPickerContainer (trigger) {
      return document.querySelector('.whitePicker')
    },
  },
  watch: {
    inputNewCard () {
      if (this.transactionType === 'subscription') {
        this.saveCardFuture = true
        this.disableSaveCardFuture = true;
      } else {
        this.saveCardFuture = false
      }

      this.currentCard = null
      this.card_number = false
      this.code = ''
      this.date = ''
      document.querySelectorAll('.request-popup__label-radio').forEach(label => label.className.includes('checked') && label.classList.remove('checked'))
      document.querySelectorAll('.request-popup__field-radio').forEach(input => {
        if (input.value === `${this.inputNewCard}`) {
          input.closest('.request-popup__label-radio').classList.add('checked')
        }
      })
    },
  },
  components: {
    SvgIcon,
    Spinner,
  },
}
</script>
